<template>
  <ion-menu side="start" content-id="global-main-content">
    <ion-header></ion-header>
    <ion-content>
      <div style="margin-top: 1.5em; display: block !important; padding-left: 1.4em; margin-top: 3em;">
        Обраний профіль керівника:
        <div style="padding-bottom: 1em; margin-top: .3em; font-weight: 600; color: rgb(87, 66, 157); font-size: 1.2em; line-height: 1.3em;">
          {{ teacherName }}
        </div>
      </div>
      <ion-item @click="navigateToCart" class="d-flex" style="align-items: center;">
        <ion-icon :icon="helpCircleOutline" class="margin-r" style="margin-right: .5em;"></ion-icon>Довідник
      </ion-item>
      <ion-item @click="logout">
        <ion-icon :icon="logOutOutline" class="margin-r" style="margin-right: .5em;"></ion-icon>Вихід
      </ion-item>
    </ion-content>
  </ion-menu>

  <!-- Основной контент -->
  <div id="global-main-content">
    <router-view></router-view>
  </div>

  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { IonMenu, IonItem, IonHeader, IonContent, IonIcon, menuController } from '@ionic/vue';
import { helpCircleOutline, logOutOutline } from 'ionicons/icons';
import { useRouter } from 'vue-router';
import axios from 'axios';

const router = useRouter();
const teacherName = localStorage.getItem('teacher_name');
async function navigateToCart() {
  await menuController.close(); // Закрываем меню
  router.push('/tabs/FAQ');
}

const logout = async () => {
  const refreshToken = localStorage.getItem('refreshToken');

  if (refreshToken) {
    try {
      await axios.post('https://test.stablemanager.com.ua/api/logout', { refreshToken });
      console.log('Logout successful');
    } catch (error) {
      console.error('Logout failed', error);
    }

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  await menuController.close();
  router.replace('/tabs/login');
};
</script>
